import Vue from 'vue'
import Router from 'vue-router'
import { store } from './store'
Vue.use(Router)
function loadView(view) {
  return () => import(/* webpackChunkName: "[request]" */ `@/views/${view}.vue`)
}
export const router = new Router({
  mode: process.env.CORDOVA_PLATFORM || process.env.IS_ELECTRON ? 'hash' : 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'dashboard',
      component: loadView('dashboard'),
      meta: { scope: 2 }
    },
    {
      path: '/myDashboard',
      name: 'myDashboard',
      component: loadView('myDashboard'),
      meta: { scope: 1 }
    },
    {
      path: '/admin',
      name: 'admin',
      component: loadView('admin'),
      meta: { scope: 2 }
    },
    {
      path: '/orders',
      name: 'orders',
      component: loadView('orders'),
      meta: { scope: 2 }
    },
    {
      path: '/invoices',
      name: 'invoices',
      component: loadView('invoices'),
      meta: { scope: 2 }
    },
    {
      path: '/myInvoices',
      name: 'myInvoices',
      component: loadView('myInvoices'),
      meta: { scope: 1 }
    },
    {
      path: '/myOrders',
      name: 'myOrders',
      component: loadView('myOrders'),
      meta: { scope: 1 }
    },
    {
      path: '/login',
      name: 'login',
      component: loadView('login'),
      meta: { scope: 0, keepAlive: false }
    },
    {
      path: '/newOrder',
      name: 'newOrder',
      component: loadView('newOrder'),
      meta: { scope: 2 }
    },
    {
      path: '/myNewOrder',
      name: 'myNewOrder',
      component: loadView('myNewOrder'),
      meta: { scope: 1 }
    },
    {
      path: '/clients',
      name: 'clients',
      component: loadView('clients'),
      meta: { scope: 2 }
    },
    {
      path: '/items',
      name: 'items',
      component: loadView('items'),
      meta: { scope: 2 }
    },
    {
      path: '/logistics',
      name: 'logistics',
      component: loadView('logistics'),
      meta: { scope: 2 }
    },
    {
      path: '/labels',
      name: 'labels',
      component: loadView('labels'),
      meta: { scope: 2 }
    },
    {
      path: '/settings', name: 'settings',
      component: loadView('settings'),
      meta: { scope: 2 }
    },
    {
      path: '/vehicles', name: 'vehicles',
      component: loadView('vehicles'),
      meta: { scope: 2 }
    },
    {
      path: '/drivers', name: 'drivers',
      component: loadView('drivers'),
      meta: { scope: 2 }
    },
    {
      path: '/employees', name: 'employees',
      component: loadView('employees'),
      meta: { scope: 2 }
    },
    {
      path: '/sortItems', name: 'sortItems',
      component: loadView('sortItems'),
      meta: { scope: 2 }
    },
    {
      path: '/icp', name: 'icp',
      component: loadView('icp'),
      meta: { scope: 2 }
    },
    {
      path: '/salesGB', name: 'salesGB',
      component: loadView('salesGB'),
      meta: { scope: 2 }
    },
    {
      path: '/outstandingDebtors', name: 'outstandingDebtors',
      component: loadView('outstandingDebtors'),
      meta: { scope: 2 }
    },
    {
      path: '/revenue', name: 'revenue',
      component: loadView('revenue'),
      meta: { scope: 2 }
    },
    {
      path: '/createPayment/:id', name: 'createPayment',
      component: loadView('createPayment'),
      meta: { scope: 0 }
    },
    {
      path: '/redirect', name: 'redirect',
      component: loadView('redirect'),
      meta: { scope: 0 }
    },
    {
      path: '/tables/:id', name: 'tables',
      component: loadView('tables'),
      meta: { scope: 2 }
    },
    {
      path: '/cratesBalance', name: 'cratesBalance',
      component: loadView('cratesBalance'),
      meta: { scope: 2 }
    },
    {
      path: '/suppliers', name: 'suppliers',
      component: loadView('suppliers'),
      meta: { scope: 2 }
    },
    {
      path: '/lastOrderDate', name: 'lastOrderDate',
      component: loadView('lastOrderDate'),
      meta: { scope: 2 }
    },
    {
      path: '/timesheets', name: 'timesheets',
      component: loadView('timesheets'),
      meta: { scope: 2 }
    },
    {
      path: '/camt053Parser', name: 'camt053Parser',
      component: loadView('camt053Parser'),
      meta: { scope: 2 }
    },
    {
      path: '/VATDeclaration', name: 'VATDeclaration',
      component: loadView('VATDeclaration'),
      meta: { scope: 2 }
    },
    {
      path: '/quotes', name: 'quotes',
      component: loadView('quotes'),
      meta: { scope: 2 }
    },
    {
      path: '/newPurchaseOrder', name: 'newPurchaseOrder',
      component: loadView('newPurchaseOrder'),
      meta: { scope: 2 }
    },
    {
      path: '/purchaseOrders', name: 'purchaseOrders',
      component: loadView('purchaseOrders'),
      meta: { scope: 2 }
    },
    {
      path: '/purchaseInvoices', name: 'purchaseInvoices',
      component: loadView('purchaseInvoices'),
      meta: { scope: 2 }
    },
    {
      path: '/viewQuote/:id', name: 'viewQuote',
      component: loadView('viewQuote'),
      meta: { scope: 0 }
    },
    {
      path: '/priceAdjustments', name: 'priceAdjustments',
      component: loadView('priceAdjustments'),
      meta: { scope: 2 }
    },
    {
      path: '/itemsSalesHistory', name: 'itemsSalesHistory',
      component: loadView('itemsSalesHistory'),
      meta: { scope: 2 }
    },
    {
      path: '/tv', name: 'tv',
      component: loadView('tv'),
      meta: { scope: 2 }
    },
    {
      path: '/newAccount', name: 'newAccount',
      component: loadView('newAccount'),
      meta: { scope: 0 }
    },
    {
      path: '/newAccount/:client_id', name: 'newAccountWithId',
      component: loadView('newAccount'),
      meta: { scope: 0 }
    },
    {
      path: '/ledger', name: 'ledger',
      component: loadView('ledger'),
      meta: { scope: 2 }
    },
    {
      path: '/purchaseItems', name: 'purchaseItems',
      component: loadView('purchaseItems'),
      meta: { scope: 2 }
    },
    {
      path: '/traceability', name: 'traceability',
      component: loadView('traceability'),
      meta: { scope: 2 }
    },
    {
      path: '/stock', name: 'stock',
      component: loadView('stock'),
      meta: { scope: 2 }
    },
    {
      path: '/clientsCratesBalance', name: 'clientsCratesBalance',
      component: loadView('clientsCratesBalance'),
      meta: { scope: 2 }
    },
    {
      path: '/itemsRevenue', name: 'itemsRevenue',
      component: loadView('itemsRevenue'),
      meta: { scope: 2 }
    },
    {
      path: '/itemsRevenueByClient', name: 'itemsRevenueByClient',
      component: loadView('itemsRevenueByClient'),
      meta: { scope: 2 }
    },
    {
      path: '/salesOutsideEU', name: 'salesOutsideEU',
      component: loadView('salesOutsideEU'),
      meta: { scope: 2 }
    },

  ]
})
router.beforeEach(async (to, from, next) => {
  function proceed() {
    let scope = store.state.client.scope
    if (to.path === '/login') {
      if (scope == 1) next('/myDashboard')
      else next('/')
    }
    else if (to.name === 'newAccountWithId') {
      if (scope == 1) next('/myDashboard')
      else next('/')
    }
    else if (scope >= to.meta.scope) {
      next()
    }
    else if (scope == 1) next('/myDashboard')
    else next('/')
  }
  if (!store.getters.loggedIn()) {
    if (to.meta.scope === 0) {
      next()
    } else if (to.path === '/login') {
      next()
    }
    else {
      next('/login')
    }
    let unwatch = store.watch(store.getters.loggedIn, function(loggedIn) {
      if (loggedIn) {
        proceed()
        unwatch()
      }
    })
  } else {
    proceed()
  }
})